import * as FullStory from '@fullstory/browser';
import React from 'react';
import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';

import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import './i18n';
import 'react-toastify/dist/ReactToastify.css';

FullStory.init({ orgId: `${process.env.REACT_APP_FULLSTORY_ORG_ID}` });

(async (): Promise<void> => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<App />);

  serviceWorker.register();
})();
