import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'MarkPro, Arial, sans-serif',
    },
    palette: {
        mode: 'dark'
    }
});

export default theme;